export const STATES = [
  {
    name: 'New South Wales',
    label: 'NSW',
    value: 'NSW'
  },
  {
    name: 'Victoria',
    label: 'VIC',
    value: 'VIC'
  },
  {
    name: 'Queensland',
    label: 'QLD',
    value: 'QLD'
  },
  {
    name: 'South Australia',
    label: 'SA',
    value: 'SA'
  },
  {
    name: 'Western Australia',
    label: 'WA',
    value: 'WA'
  },
  {
    name: 'Tasmania',
    label: 'TAS',
    value: 'TAS'
  },
  {
    name: 'Northern Territory',
    label: 'NT',
    value: 'NT'
  },
  {
    name: 'Australian Capital Territory',
    label: 'ACT',
    value: 'ACT'
  }
]
